import React, { useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import markdown from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import vsTheme from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';

SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('html', html);

const InputField = ({ id, label, type, value, onChange, placeholder, tooltip }) => (
  <div className="relative">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 flex items-center">
      {label}
      {tooltip && (
        <span className="ml-2 group relative">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
          <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-64 text-center">
            {tooltip}
          </span>
        </span>
      )}
    </label>
    <div className="mt-1">
      <input
        type={type}
        name={id}
        id={id}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

const CodeBlock = ({ language, code }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="relative">
      <button
        onClick={copyToClipboard}
        className="absolute top-2 right-4 px-2 py-1 text-xs font-semibold text-white bg-gray-700 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 z-10"
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
      <div className="max-h-96 overflow-auto">
        <SyntaxHighlighter 
          language={language} 
          style={vsTheme} 
          wrapLines={true} 
          wrapLongLines={true}
          customStyle={{
            margin: 0,
            borderRadius: '0.375rem',
            padding: '1rem',
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const App = () => {
  const [apiKey, setApiKey] = useState('');
  const [urls, setUrls] = useState('');
  const [excludes, setExcludes] = useState('');
  const [includes, setIncludes] = useState('');
  const [limit, setLimit] = useState(10);
  const [fileType, setFileType] = useState('txt');
  const [onlyMainContent, setOnlyMainContent] = useState(true);
  const [includeHtml, setIncludeHtml] = useState(false);
  const [includeRawHtml, setIncludeRawHtml] = useState(false);
  const [exportSeparately, setExportSeparately] = useState(false);
  const [removeGitBookFooter, setRemoveGitBookFooter] = useState(false);
  const [includePageInfo, setIncludePageInfo] = useState(false);
  const [insertPageInfoEvery10Lines, setInsertPageInfoEvery10Lines] = useState(false);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!apiKey.trim()) {
      setError('API Key is required');
      return;
    }

    if (!urls.trim()) {
      setError('Server Website URL is required');
      return;
    }

    setIsLoading(true);
    setStatus('Processing...');
    setDocuments([]);

    const urlList = urls.split(',').map(url => url.trim());
    const excludeList = excludes ? excludes.split(',').map(exclude => exclude.trim()) : [];
    const includeList = includes ? includes.split(',').map(include => include.trim()) : [];

    const crawlParams = {
      crawlerOptions: {
        excludes: excludeList,
        includes: includeList,
        limit: limit,
        generateImgAltText: false,
        returnOnlyUrls: false,
        mode: "default",
        ignoreSitemap: false,
        allowBackwardCrawling: false,
        allowExternalContentLinks: false
      },
      pageOptions: {
        headers: {},
        includeHtml: includeHtml,
        includeRawHtml: includeRawHtml,
        onlyMainContent: onlyMainContent,
        replaceAllPathsWithAbsolutePaths: true,
        screenshot: false,
        waitFor: 0
      }
    };

    try {
      let allDocuments = [];
      for (const url of urlList) {
        setStatus(`Crawling: ${url}`);
        
        const options = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            url,
            ...crawlParams
          })
        };

        const response = await fetch('https://api.firecrawl.dev/v0/crawl', options);
        const data = await response.json();
        const jobId = data.jobId;

        let crawlStatus = 'active';
        let statusData;
        while (crawlStatus === 'active') {
          const statusOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${apiKey}` }
          };
          const statusResponse = await fetch(`https://api.firecrawl.dev/v0/crawl/status/${jobId}`, statusOptions);
          statusData = await statusResponse.json();
          crawlStatus = statusData.status;
          setStatus(`Status: ${crawlStatus} (${statusData.current}/${statusData.total})`);
          await new Promise(resolve => setTimeout(resolve, 5000));
        }

        if (crawlStatus === 'completed') {
          const results = statusData.data;
          allDocuments = allDocuments.concat(results.map(result => ({
            title: result.metadata.title,
            url: result.metadata.sourceURL,
            content: formatContent(result.markdown, result.metadata.title, result.metadata.sourceURL),
            html: result.html,
            rawHtml: result.rawHtml
          })));
        } else {
          setStatus(`Crawling failed for URL: ${url}`);
        }
      }

      setDocuments(allDocuments);
      setStatus('Document creation completed.');
    } catch (error) {
      setStatus(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const formatContent = (content, title, url) => {
    let formattedContent = removeGitBookFooter ? removeFooter(content) : content;
    
    if (includePageInfo) {
      formattedContent = `${title}:\n${url}\n\n${formattedContent}`;
    }
    
    if (insertPageInfoEvery10Lines) {
      const lines = formattedContent.split('\n');
      const newLines = [];
      for (let i = 0; i < lines.length; i++) {
        newLines.push(lines[i]);
        if ((i + 1) % 10 === 0) {
          newLines.push(`\n${title}:\n${url}\n`);
        }
      }
      formattedContent = newLines.join('\n');
    }
    
    return formattedContent;
  };

  const removeFooter = (content) => {
    const lines = content.split('\n');
    let lastIndex = lines.length - 1;
    
    // Remove empty lines from the end
    while (lastIndex >= 0 && lines[lastIndex].trim() === '') {
      lastIndex--;
    }

    // Remove "Last updated" line if present
    if (lastIndex >= 0 && lines[lastIndex].startsWith('Last updated')) {
      lastIndex--;
    }

    return lines.slice(0, lastIndex + 1).join('\n').trim();
  };

  const downloadTextFile = (content, filename) => {
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleDownload = () => {
    if (exportSeparately) {
      documents.forEach((doc, index) => {
        const content = `${doc.content}`;
        downloadTextFile(content, `document_${index + 1}.${fileType}`);
      });
    } else {
      const combinedContent = documents.map(doc => doc.content).join('\n\n');
      downloadTextFile(combinedContent, `document.${fileType}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-6 py-8 bg-gradient-to-r from-[#2461f1] to-[#8044f6] sm:px-10 text-center">
            <h1 className="text-4xl font-bold text-white">ServerAssistantAI Document Creator</h1>
          </div>
          <div className="px-6 py-8 sm:px-10">
            <form onSubmit={handleSubmit} className="space-y-6">
              <InputField
                id="api-key"
                label="API Key"
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter your Firecrawl API key"
                tooltip={<>You can obtain your API key at <a href="https://www.firecrawl.dev/app/api-keys" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-100 underline">https://www.firecrawl.dev/app/api-keys</a></>}
              />
              <InputField
                id="urls"
                label="Server Website URLs"
                type="text"
                value={urls}
                onChange={(e) => setUrls(e.target.value)}
                placeholder="Enter URLs separated by commas"
              />
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                <InputField
                  id="excludes"
                  label="Excludes"
                  type="text"
                  value={excludes}
                  onChange={(e) => setExcludes(e.target.value)}
                  placeholder="Enter patterns to exclude, separated by commas"
                />
                <InputField
                  id="includes"
                  label="Includes"
                  type="text"
                  value={includes}
                  onChange={(e) => setIncludes(e.target.value)}
                  placeholder="Enter patterns to include, separated by commas"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputField
                  id="limit"
                  label="Limit"
                  type="number"
                  value={limit}
                  onChange={(e) => setLimit(parseInt(e.target.value))}
                  placeholder="Enter maximum number of pages to crawl"
                />
                <div>
                  <label htmlFor="file-type" className="block text-sm font-medium text-gray-700">
                    Export File Type
                  </label>
                  <select
                    id="file-type"
                    name="file-type"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="txt">TXT</option>
                    <option value="md">MD</option>
                  </select>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="only-main-content"
                      name="only-main-content"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={onlyMainContent}
                      onChange={(e) => setOnlyMainContent(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="only-main-content" className="font-medium text-gray-700">
                      Only Main Content
                    </label>
                  </div>
                  </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="include-html"
                      name="include-html"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={includeHtml}
                      onChange={(e) => setIncludeHtml(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="include-html" className="font-medium text-gray-700">
                      Include HTML
                    </label>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="include-raw-html"
                      name="include-raw-html"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={includeRawHtml}
                      onChange={(e) => setIncludeRawHtml(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="include-raw-html" className="font-medium text-gray-700">
                      Include Raw HTML
                    </label>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="export-separately"
                      name="export-separately"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={exportSeparately}
                      onChange={(e) => setExportSeparately(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="export-separately" className="font-medium text-gray-700">
                      Export Separately Per Page
                    </label>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remove-gitbook-footer"
                      name="remove-gitbook-footer"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={removeGitBookFooter}
                      onChange={(e) => setRemoveGitBookFooter(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remove-gitbook-footer" className="font-medium text-gray-700">
                      Remove GitBook Footer (WIP)
                    </label>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="include-page-info"
                      name="include-page-info"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={includePageInfo}
                      onChange={(e) => setIncludePageInfo(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="include-page-info" className="font-medium text-gray-700">
                      Include Page Name and Link
                    </label>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="insert-page-info-every-10-lines"
                      name="insert-page-info-every-10-lines"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={insertPageInfoEvery10Lines}
                      onChange={(e) => setInsertPageInfoEvery10Lines(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="insert-page-info-every-10-lines" className="font-medium text-gray-700">
                      Insert Page Info Every 10 Lines
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#0747ca] hover:bg-[#0747ca]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0747ca] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Processing...' : 'Create Document'}
                </button>
              </div>
            </form>
          </div>
          {error && (
            <div className="bg-red-50 border-t border-b border-red-200 px-6 py-4">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}
          {status && (
            <div className="bg-blue-50 border-t border-b border-blue-200 px-6 py-4">
              <p className="text-sm text-blue-700">{status}</p>
            </div>
          )}
          {documents.length > 0 && (
            <div className="px-6 py-8 bg-gray-50">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Documents:</h2>
              <div className="space-y-8">
                {documents.map((doc, index) => (
                  <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">{doc.title}</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">{doc.url}</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:px-6">
                          <CodeBlock language="markdown" code={doc.content} />
                        </div>
                        {includeHtml && (
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">HTML</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <CodeBlock language="html" code={doc.html} />
                            </dd>
                          </div>
                        )}
                        {includeRawHtml && (
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Raw HTML</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <CodeBlock language="html" code={doc.rawHtml} />
                            </dd>
                          </div>
                        )}
                      </dl>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-8">
                <button
                  onClick={handleDownload}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Download Document(s) (.{fileType})
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer className="mt-8 text-center text-sm text-gray-500">
        © 2024 <a href="https://code-solutions.dev/" className="text-indigo-600 hover:text-indigo-500">CodeSolutions</a>. All rights reserved. ServerAssistantAI is a product of CodeSolutions.
      </footer>
    </div>
  );
};

export default App;